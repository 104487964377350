import {UserSidebar} from "../components/Sidebar/Sidebar";
import React from "react";
import {LaunchConfigSection} from "../components/LaunchConfigSection/LaunchConfigSection";
import call, {encodeObjectPath} from "../api";
import "pyloncss/css/pylon.css";
import ModalCopyBox from "../components/ModalCopyBox/ModalCopyBox";
import moment from "moment";
import BaseApp from "./BaseApp";

export default class User extends BaseApp {
    interval = null;

    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            launch_configs: [],
            sessions: []
        };
    }

    componentDidMount() {
        super.componentDidMount()

        this.getApps();
        this.getSessions();
        this.interval = window.setInterval(
            () => this.intervalCheck(),
            5000
        );
    }

    componentWillUnmount() {
        window.clearInterval(this.interval);
    }

    /*
     * Fetch data from backend
     */
    getApps() {
        call("GET",
            "generic/console_cfg",
            (status, console_content) => {
                if (status !== 200) {
                    this.sendNotification("error", "Error while fetching apps", console_content.message, 30);
                    return;
                }

                call("GET",
                    "generic/bastion_cfg",
                    (status, bastion_content) => {
                        if (status !== 200) {
                            this.sendNotification("error", "Error while fetching apps", bastion_content.message, 30);
                            return;
                        }

                        this.setState({"launch_configs": {...this._objsToDict(console_content), ...this._objsToDict(bastion_content)}});
                    });
            });
    }

    getSessions() {
        call("GET",
            "generic/console_sess",
            (status, console_content) => {
                if (status !== 200) {
                    this.sendNotification("error", "Error while fetching sessions", console_content.message, 30);
                    return;
                }

                call(
                    "GET",
                    "generic/bastion_sess",
                    (status, bastion_content) => {
                        if (status !== 200) {
                            this.sendNotification("error", "Error while fetching sessions", bastion_content.message, 30);
                            return;
                        }

                        this.setState({sessions: {...this._objsToDict(console_content), ...this._objsToDict(bastion_content)}});
                    });
            });
    }

    _objsToDict(content) {
        // Create a dict, where paths are keys, and objs are values.
        const result = {}
        for (const i in content) {
            const item = content[i];
            result[item.path] = item;
        }

        return result;
    }

    intervalCheck() {
        this.getSessions();
        this.getApps();
    }

    /*
     * Functions for buttons in LaunchConfigSection
     */
    buttonLaunchApp(launch_config, event) {
        event.stopPropagation();

        const url = launch_config.type === "bastion_cfg" ? `/bastion/launchconfig/${encodeObjectPath(launch_config.path)}` : `/console/launchconfig/${encodeObjectPath(launch_config.path)}`;
        const name = launch_config.name ? launch_config.name : launch_config.path;

        call("GET", url, (s, p) => {
            if (s !== 200) {
                this.sendNotification("error", `Error while starting ${name}`, p.message, 30);
            } else {
                this.sendNotification("info", `Starting ${name}`, null, 5);
            }

            this.getSessions();
            this.getApps();
        });

        // Mark app as stating
        const clone = JSON.parse(JSON.stringify(launch_config));
        const clone_configs = JSON.parse(JSON.stringify(this.state.launch_configs));
        clone.state = "starting";
        clone_configs[clone.path] = clone;
        this.setState({"launch_configs": clone_configs});
    }

    buttonSessionCopy(session, event) {
        event.stopPropagation();

        if (session.type === "console_sess") {
            const credentials = JSON.parse(session.credentials);

            const linux_credentials = `export AWS_ACCESS_KEY_ID="${credentials.sessionId}"
export AWS_SECRET_ACCESS_KEY="${credentials.sessionKey}"
export AWS_SESSION_TOKEN="${credentials.sessionToken}"`;

            const windows_credentials = `SET AWS_ACCESS_KEY_ID=${credentials.sessionId}
SET AWS_SECRET_ACCESS_KEY=${credentials.sessionKey}
SET AWS_SESSION_TOKEN=${credentials.sessionToken}`;

            const powershell_credentials = `$Env:AWS_ACCESS_KEY_ID="${credentials.sessionId}"
$Env:AWS_SECRET_ACCESS_KEY="${credentials.sessionKey}"
$Env:AWS_SESSION_TOKEN="${credentials.sessionToken}"`;

            this.setState({
                modal: {
                    title: "AWS CLI credentials",
                    content: [
                        <p>Linux, macOS, and Unix: </p>,
                        <ModalCopyBox text={linux_credentials}/>,
                        <br/>,
                        <p>Windows: </p>,
                        <ModalCopyBox text={windows_credentials}/>,
                        <br/>,
                        <p>PowerShell: </p>,
                        <ModalCopyBox text={powershell_credentials}/>,
                    ]
                }
            })
            return;
        }

        if (session.type === "bastion_sess") {
            this.setState({
                modal: {
                    "title": "Fetching info...",
                    "content": []
                }
            });

            call("GET", `/bastion/connect/${encodeObjectPath(session.path)}`, (status, content) => {
                if (status === 425) {
                    this.setState({
                        modal: {
                            "title": "Bastion is booting up, please wait...",
                            "content": [
                                <p>Will retry in 3 seconds.</p>
                            ]
                        }
                    });
                    window.setTimeout(() => {
                        this.buttonSessionOpen(session, event)
                    }, 3000);
                    return;
                }

                if (status === 410) {
                    this.buttonSessionStop(session, event);
                    this.setState({
                        modal: {
                            "title": "The bastion instance has been terminated.",
                            "content": [
                                <p>Session termination was requested.</p>
                            ]
                        }
                    });
                    return;
                }

                const linux = `AWS_ACCESS_KEY_ID="${content.env.AWS_ACCESS_KEY_ID}" ` +
                              `AWS_SECRET_ACCESS_KEY="${content.env.AWS_SECRET_ACCESS_KEY}" ` +
                              `AWS_SESSION_TOKEN="${content.env.AWS_SESSION_TOKEN}" ` +
                              `AWS_REGION="${content.env.AWS_REGION}" ` +
                              `aws ssm start-session --target ${content.instance_id} --document-name AWS-StartPortForwardingSession --parameters '{"portNumber": ["$REMOTE_PORT"]}'`;

                const linux_remote = `AWS_ACCESS_KEY_ID="${content.env.AWS_ACCESS_KEY_ID}" ` +
                              `AWS_SECRET_ACCESS_KEY="${content.env.AWS_SECRET_ACCESS_KEY}" ` +
                              `AWS_SESSION_TOKEN="${content.env.AWS_SESSION_TOKEN}" ` +
                              `AWS_REGION="${content.env.AWS_REGION}" ` +
                              `aws ssm start-session --target ${content.instance_id} --document-name AWS-StartPortForwardingSessionToRemoteHost --parameters '{"portNumber": ["$REMOTE_PORT"], "host": ["$REMOTE_HOST"]}'`;

                const windows_cmd = `SET AWS_ACCESS_KEY_ID=${content.env.AWS_ACCESS_KEY_ID}\n` +
                                    `SET AWS_SECRET_ACCESS_KEY=${content.env.AWS_SECRET_ACCESS_KEY}\n` +
                                    `SET AWS_SESSION_TOKEN=${content.env.AWS_SESSION_TOKEN}\n` +
                                    `SET AWS_REGION=${content.env.AWS_REGION}\n` +
                                    `aws ssm start-session --target ${content.instance_id} --document-name AWS-StartPortForwardingSession --parameters {"portNumber": ["$REMOTE_PORT"]}`;

                const windows_cmd_remote =  `SET AWS_ACCESS_KEY_ID=${content.env.AWS_ACCESS_KEY_ID}\n` +
                                            `SET AWS_SECRET_ACCESS_KEY=${content.env.AWS_SECRET_ACCESS_KEY}\n` +
                                            `SET AWS_SESSION_TOKEN=${content.env.AWS_SESSION_TOKEN}\n` +
                                            `SET AWS_REGION=${content.env.AWS_REGION}\n` +
                                            `aws ssm start-session --target ${content.instance_id} --document-name AWS-StartPortForwardingSessionToRemoteHost --parameters {"portNumber": ["$REMOTE_PORT"], "host": ["$REMOTE_HOST"]}`;

                const windows_ps = `$env:AWS_ACCESS_KEY_ID='${content.env.AWS_ACCESS_KEY_ID}'; ` +
                                   `$env:AWS_SECRET_ACCESS_KEY='${content.env.AWS_SECRET_ACCESS_KEY}'; ` +
                                   `$env:AWS_SESSION_TOKEN='${content.env.AWS_SESSION_TOKEN}'; ` +
                                   `$env:AWS_REGION='${content.env.AWS_REGION}'; ` +
                                   `aws ssm start-session --target ${content.instance_id} --document-name AWS-StartPortForwardingSession --parameters '{"portNumber": ["$REMOTE_PORT"]}'`;

                const windows_ps_remote =  `$env:AWS_ACCESS_KEY_ID='${content.env.AWS_ACCESS_KEY_ID}'; ` +
                                           `$env:AWS_SECRET_ACCESS_KEY='${content.env.AWS_SECRET_ACCESS_KEY}'; ` +
                                           `$env:AWS_SESSION_TOKEN='${content.env.AWS_SESSION_TOKEN}'; ` +
                                           `$env:AWS_REGION='${content.env.AWS_REGION}'; ` +
                                           `aws ssm start-session --target ${content.instance_id} --document-name AWS-StartPortForwardingSessionToRemoteHost --parameters '{"portNumber": ["$REMOTE_PORT"], "host": ["$REMOTE_HOST"]}'`;

                const light_text = {fontWeight: 300};
                this.setState({
                    modal: {
                        "title": "Port forwarding",
                        "content": [
                            <p style={light_text}>Learn more about <a href="https://aws.amazon.com/blogs/aws/new-port-forwarding-using-aws-system-manager-sessions-manager/">port forwarding</a>,
                                and <a href="https://aws.amazon.com/about-aws/whats-new/2022/05/aws-systems-manager-support-port-forwarding-remote-hosts-using-session-manager/">remote port forwarding</a>.</p>,
                            <p style={light_text}>Replace $REMOTE_PORT and $REMOTE_HOST at the end of the commands.</p>,
                            <h3>Port forwarding from the bastion</h3>,
                            <p>Linux/macOS/Unix:</p>,
                            <ModalCopyBox text={linux}/>,
                            <p>Windows:</p>,
                            <ModalCopyBox text={windows_cmd}/>,
                            <p>PowerShell:</p>,
                            <ModalCopyBox text={windows_ps}/>,
                            <h3>Port forwarding from a remote host</h3>,
                            <p>Linux/macOS/Unix:</p>,
                            <ModalCopyBox text={linux_remote}/>,
                            <p>Windows:</p>,
                            <ModalCopyBox text={windows_cmd_remote}/>,
                            <p>PowerShell:</p>,
                            <ModalCopyBox text={windows_ps_remote}/>
                        ]
                    }
                });
            })
        }
    }

    buttonSessionOpen(session, event) {
        event.stopPropagation();

        if (session.type === "console_sess") {
            const credentials = JSON.parse(session.credentials);
            window.open(credentials.url).focus();
            return;
        }

        if (session.type === "bastion_sess") {
            this.setState({
                modal: {
                    "title": "Fetching info...",
                    "content": []
                }
            });

            call("GET", `/bastion/connect/${encodeObjectPath(session.path)}`, (status, content) => {
                if (status === 425) {
                    this.setState({
                        modal: {
                            "title": "Bastion is booting up, please wait...",
                            "content": [
                                <p>Will retry in 3 seconds.</p>
                            ]
                        }
                    });
                    window.setTimeout(() => {
                        this.buttonSessionOpen(session, event)
                    }, 3000);
                    return;
                }

                if (status === 410) {
                    this.buttonSessionStop(session, event);
                    this.setState({
                        modal: {
                            "title": "The bastion instance has been terminated.",
                            "content": [
                                <p>Session termination was requested.</p>
                            ]
                        }
                    });
                    return;
                }

                const light_text = {fontWeight: 300};

                const linux = `AWS_ACCESS_KEY_ID="${content.env.AWS_ACCESS_KEY_ID}" ` +
                              `AWS_SECRET_ACCESS_KEY="${content.env.AWS_SECRET_ACCESS_KEY}" ` +
                              `AWS_SESSION_TOKEN="${content.env.AWS_SESSION_TOKEN}" ` +
                              `AWS_REGION="${content.env.AWS_REGION}" ` +
                              `aws ssm start-session --target "${content.instance_id}" --document-name "${content.ssm_document}"`;

                const windows_cmd = `SET AWS_ACCESS_KEY_ID=${content.env.AWS_ACCESS_KEY_ID}\n` +
                                    `SET AWS_SECRET_ACCESS_KEY=${content.env.AWS_SECRET_ACCESS_KEY}\n` +
                                    `SET AWS_SESSION_TOKEN=${content.env.AWS_SESSION_TOKEN}\n` +
                                    `SET AWS_REGION=${content.env.AWS_REGION}\n` +
                                    `aws ssm start-session --target ${content.instance_id} --document-name ${content.ssm_document}`;

                const windows_ps = `$env:AWS_ACCESS_KEY_ID='${content.env.AWS_ACCESS_KEY_ID}'; ` +
                                   `$env:AWS_SECRET_ACCESS_KEY='${content.env.AWS_SECRET_ACCESS_KEY}'; ` +
                                   `$env:AWS_SESSION_TOKEN='${content.env.AWS_SESSION_TOKEN}'; ` +
                                   `$env:AWS_REGION='${content.env.AWS_REGION}'; ` +
                                   `aws ssm start-session --target ${content.instance_id} --document-name ${content.ssm_document}`;

                this.setState({
                    modal: {
                        "title": "Connect to a bastion",
                        "content": [
                            <p style={light_text}><a style={light_text}
                                                     href="https://docs.aws.amazon.com/systems-manager/latest/userguide/session-manager-working-with-install-plugin.html">Session
                                Manager plugin for AWS CLI</a> is required.</p>,
                            <p>Linux/macOS/Unix:</p>,
                            <ModalCopyBox text={linux}/>,
                            <p>Windows:</p>,
                            <ModalCopyBox text={windows_cmd}/>,
                            <p>PowerShell:</p>,
                            <ModalCopyBox text={windows_ps}/>
                        ]
                    }
                });
            })
        }
    }

    buttonSessionStop(session, event) {
        event.stopPropagation();

        const url = session.type === "bastion_sess" ? `bastion/${encodeObjectPath(session.path)}` : `console/${encodeObjectPath(session.path)}`;
        let name = session.name ? session.name : session.path;
        if (session.launch_config !== null && typeof session.launch_config === "object")
            name = session.launch_config.name ? session.launch_config.name : session.launch_config.path;

        call("DELETE", url, (s, p) => {
            this.getSessions()
        });
        this.sendNotification("info", `Closing ${name}`, null, 5);
    }

    /*
     * Sidebar handling
     */
    onCloseAll(event) {
        event.stopPropagation();

        if (!window.confirm("Are you sure you want to close all sessions?"))
            return;

        for (const i in this.state.sessions) {
            const session = this.state.sessions[i];
            if (session.state === "running") {
                this.buttonSessionStop(session, event);
            } else {
                let name = session.name ? session.name : session.path;
                if (session.launch_config !== null && typeof session.launch_config === "object")
                    name = session.launch_config.name ? session.launch_config.name : session.launch_config.path;

                this.sendNotification(
                    "warning",
                    "Can't close all sessions",
                    `Can't close session ${name} before it finishes starting`,
                    30
                );
            }
        }
    }

    /*
     * Transform launch configs and sessions into renderable content.
     */
    launchConfigToContent(launch_config) {
        let accountName;
        if (launch_config.type === "bastion_cfg") {
            const vpcName = launch_config.vpc.name ? launch_config.vpc.name : launch_config.vpc.path;
            const region = launch_config.vpc.region || "";
            accountName = `${vpcName} ${region}`
        }
        else
            accountName = launch_config.account.name ? launch_config.account.name : launch_config.account.path;

        const policies = [];
        for (let pol in launch_config.policies) {
            const policy = launch_config.policies[pol];
            policies.push(policy.name ? policy.name : policy.path);
        }

        const resources = [];
        if (launch_config.resources) {
            for (let res in launch_config.resources) {
                const resource = launch_config.resources[res];
                resources.push(resource.name ? resource.name : resource.path);
            }
        }

        const app = {
            "type": launch_config.type === "bastion_cfg" ? "bastion" : "console",
            "key": launch_config.path,
            "name": launch_config.name ? launch_config.name : launch_config.path,
            "accountName": accountName,
            "description": launch_config.description ? launch_config.description : null,
            "policies": policies.join(", "),
            "resources": resources.join(", "),
            "state": launch_config.state ? launch_config.state : "stopped"
        }

        app.onAppStart = (e) => {
            this.buttonLaunchApp(launch_config, e)
        };
        app.onAppCopy = (e) => {
            e.stopPropagation()
        };
        app.onAppOpen = (e) => {
            e.stopPropagation()
        };
        app.onAppStop = (e) => {
            e.stopPropagation()
        };

        return app;
    }

    launchConfigContent() {
        // Get sessions dict, by launch config
        const sessions = {};
        for (const i in this.state.sessions) {
            const session = this.state.sessions[i];

            if (session.launch_config === null || typeof session.launch_config !== "object") {
                continue;  // Manual session, skip
            }

            sessions[session.launch_config.path] = session;
        }

        const consoles = [];
        const bastions = [];
        for (const i in this.state.launch_configs) {
            const launch_config = this.state.launch_configs[i];
            let to_push;

            // If it has an open session, use the session object.
            if (typeof sessions[launch_config.path] !== "undefined") {
                to_push = this.sessionToContent(sessions[launch_config.path])
            } else {
                to_push = this.launchConfigToContent(launch_config)
            }

            if (launch_config.type === "bastion_cfg") {
                bastions.push(to_push)
            } else {
                consoles.push(to_push)
            }
        }

        return {
            "consoles": consoles,
            "bastions": bastions
        };
    }

    sessionToContent(session) {
        const from_launch_config = session.launch_config !== null && typeof session.launch_config === "object";

        let name;
        if (from_launch_config) {
            name = session.launch_config.name ? session.launch_config.name : session.launch_config.path;
        } else if (session.name) {
            name = session.name;
        } else {
            name = session.path;
        }

        let description;
        if (from_launch_config && session.launch_config.description) {
            description = session.launch_config.description;
        } else if (session.description) {
            description = session.description;
        } else {
            description = null;
        }

        let account;
        if (session.type === "bastion_sess") {
            account = session.vpc.account.name ? session.vpc.account.name : session.vpc.account.path;
            if (from_launch_config)
                if (session.launch_config.type === "bastion_cfg") {
                    const vpcName = session.launch_config.vpc.name ? session.launch_config.vpc.name : session.launch_config.vpc.path;
                    const region = session.launch_config.vpc.region || "";
                    account = `${vpcName} ${region}`
                }
        } else {
            account = session.account.name ? session.account.name : session.account.path;
        }

        const policies = [];
        for (const pol_index in session.policies) {
            const policy = session.policies[pol_index];
            policies.push(policy.name ? policy.name : policy.path);
        }

        const resources = [];
        if (session.resources) {
            for (let res in session.resources) {
                const resource = session.resources[res];
                resources.push(resource.name ? resource.name : resource.path);
            }
        }

        const app = {
            "type": session.type === "bastion_sess" ? "bastion" : "console",
            "key": session.path,
            "name": name,
            "accountName": account,
            "description": description,
            "policies": policies.join(", "),
            "resources": resources.join(", "),
            "state": session.state,
            "time": moment(session.end_time).fromNow(true)
        }

        app.onAppStart = (e) => {
            e.stopPropagation()
        };
        app.onAppCopy = (e) => {
            this.buttonSessionCopy(session, e)
        };
        app.onAppOpen = (e) => {
            this.buttonSessionOpen(session, e)
        };
        app.onAppStop = (e) => {
            this.buttonSessionStop(session, e)
        };
        return app;
    }

    sessionsContent() {
        const content = [];

        for (const i in this.state.sessions) {
            const session = this.state.sessions[i];
            content.push(this.sessionToContent(session))
        }

        return content;
    }

    /*
     * Render
     */
    getSidebar() {
        return (
            <UserSidebar
                short={this.state.isMenuShort}
                onCloseAll={(e) => this.onCloseAll(e)}
                capabilities={this.getCapabilities()}
            />)
    }

    getContent() {
        const apps = this.launchConfigContent()

        return [
            <LaunchConfigSection
                key="Active sessions"
                title="Active sessions"
                columns={1}
                content={this.sessionsContent()}/>,
            <LaunchConfigSection
                key="Consoles"
                title="Consoles"
                columns={2}
                search={this.state.search}
                content={apps["consoles"]}/>,
            <LaunchConfigSection
                key="Bastions"
                title="Bastions"
                columns={2}
                search={this.state.search}
                content={apps["bastions"]}/>
        ]
    }
}