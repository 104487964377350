import React from "react";
import BaseApp from "../../pages/BaseApp";
import {AdminSidebar} from "../Sidebar/Sidebar";
import call, {encodeObjectPath} from "../../api";
import "./EditItem.scss";
import "pyloncss/css/pylon.css";


export default class BaseEditItem extends BaseApp {
    DISPLAY_SEARCH = false;
    CAN_DELETE = true;

    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            item: null,
        };
    }

    update() {
        this.fetchObject();
    }

    fetchObject() {
        if (this.props.create) {
            this.setState({
                "item": {}
            });
            return;
        }

        call("GET",
            `generic/${this.props.type}/${encodeObjectPath(this.props.path)}`,
            (status, content) => {
                if (status === 404) {
                    // TODO 404 page
                    return;
                }

                if (status !== 200) {
                    this.sendNotification("error", "Error while fetching content", content.message, 30);
                    return;
                }
                this.setState({item: content});
            }
        )
    }

    getSidebar() {
        return (<AdminSidebar
            short={this.state.isMenuShort}
        />);
    }

    FIELDS = []

    updateField(name, value) {
        const item_cpy = JSON.parse(JSON.stringify(this.state.item));  // Why this is the only way to deep copy, WHY!?
        item_cpy[name] = value;
        this.setState({"item": item_cpy});
    }

    validate(field, value) {
        if (field && field.validate)
            return field.validate(value);
    }

    getContent() {
        if (!this.state.item) {
            return (
                <vstack className="item-edit-page" spacing="s">
                    <span className="header">{this.props.name} - Loading...</span>
                </vstack>)
        }

        const fields = [];
        for (const i in this.FIELDS) {
            const field = this.FIELDS[i];
            fields.push(
                <field.element key={field.name}
                               value={this.state.item[field.name]}
                               onChange={(value) => this.updateField(field.name, value)}
                               validation={(value) => this.validate(field, value)}
                               sendNotification={(t, ti, te, ex) => this.sendNotification(t, ti, te, ex)}
                               {...field}
                />
            )
        }

        const delete_style = {display: (this.props.create || !this.CAN_DELETE) ? "none" : null};

        return (
            <vstack className="item-edit-page" spacing="s">
                <span
                    className="header">{this.props.name} {this.state.item.name ? this.state.item.name : this.state.item.path}</span>
                <vstack className="item-form" spacing="xxs">
                    {fields}
                </vstack>
                <hstack spacing="s">
                    <div className="button button-save" onClick={() => this.save()}>Save</div>
                    <spacer/>
                    <div className="button button-delete" onClick={() => this.del()} style={delete_style}>Delete</div>
                </hstack>
            </vstack>

        );
    }

    isAllValid() {
        for (const f in this.FIELDS) {
            const field = this.FIELDS[f];
            if (this.validate(field, this.state.item[field.name])) {
                return false;
            }
        }
        return true;
    }

    _prepArray(arr) {
        const result = [];
        for (const i in arr) {
            const item = arr[i];
            if (typeof item === "object") {
                result.push(item.path);
                continue;
            }

            result.push(item);
        }
        return result;
    }

    SUBMIT_FIELDS = [
        "version",
    ]

    prepForSave() {
        const non_read_only_fields = this.FIELDS.filter((field) => !field.readOnly);
        const submit_fields = [...this.SUBMIT_FIELDS, ...non_read_only_fields.map((i) => i.name)];
        const result = {};

        for (const f in submit_fields) {
            const field = submit_fields[f];
            let value = this.state.item[field];

            // if (typeof value === "string")
            //     try {
            //         value = decodeURI(value)
            //     } catch {}


            if (Array.isArray(value)) {
                result[field] = this._prepArray(value);
                continue;
            }

            if (typeof value === "object")
                value = value.path;
            else if (typeof value === "undefined") {
                if (this.props.create)
                    continue;
                value = null;
            }

            result[field] = value;
        }

        return result;
    }

    getPath() {
        if (!this.props.create)
            return this.props.path;

        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for (let i = 0; i < 16; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }

        return `${this.props.type}.${result}`
    }

    save() {
        if (!this.isAllValid()) {
            this.sendNotification(
                "error",
                "Can't save",
                `Can't save ${this.props.name} ${this.state.item.name} - validation errors.`,
                10
            );
            return;
        }

        let item = this.state.item;
        if (this.state.item.path) {
            item = JSON.parse(JSON.stringify(this.state.item));
            delete item.path;
        }

        call(
            this.props.create ? "POST" : "PATCH",
            `generic/${this.props.type}/${encodeObjectPath(this.getPath())}`,
            (code, response) => {
                if (code !== 200) {
                    this.sendNotification(
                        "error",
                        "Error while saving",
                        response.message,
                        20
                    )
                    return;
                }

                window.location.href = `/admin/${this.props.type}`;
            },
            this.prepForSave(item)
        );
    }

    del() {
        if (this.props.create)
            return;

        if (!window.confirm(`Are you sure you want to DELETE ${this.props.name} ${this.state.item.name}?`))
            return;

        call(
            "DELETE",
            `generic/${this.props.type}/${encodeObjectPath(this.props.path)}`,
            (code, response) => {
                if (code !== 200) {
                    this.sendNotification(
                        "error",
                        "Error while deleting",
                        response.message,
                        20
                    )
                    return;
                }

                window.location.href = `/admin/${this.props.type}`;
            },
            {
                "version": this.state.item.version
            }
        );
    }
}